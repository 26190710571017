import React from "react";
import { useState } from "react";
import emailjs from '@emailjs/browser';
import '../assets/style/contact.css';
import LoadingModal from "../modals/LoadingModal";
import SendModal from "../modals/SendModal";


const Contact = () =>{

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [prestaCoiffure, setPrestaCoiffure] = useState(false);
    const [prestaOngle, setPrestaOngle] = useState(false);
    const [prestaSkin, setPrestaSkin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSend, setShowSend] = useState(false);

    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);

        const content = {
            to_name: 'EV Coiffure & Esthétique',
            from_name: name,
            from_email: email,
            from_phone: phone,
            presta_coiffure: prestaCoiffure ? 'Coiffure' : '',
            presta_ongle: prestaOngle ? 'Esthétique' : '',
            presta_skin: prestaSkin ? 'Bien-être' : '',
            from_message: message,
        };

        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
            content, 
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        )
        .then((response) =>{
            setLoading(false);
            setShowSend(true);
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
            setPrestaCoiffure(false);
            setPrestaOngle(false);
            setPrestaSkin(false);
        });

    }
    
    const handleCloseMessageModal = () =>{
        setShowSend(false);
    }
    return(
        <section className="home">
            <div className="body-info">
                <div className="qrMap ">
                    <article className="localisation">
                        <h2 className="mb-5">Nous sommes ici:</h2>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.3558186335663!2d55.532964776020556!3d-21.257378481013923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2178754e586d9d61%3A0x6801fc4e4b19eaaa!2sVE%20Coiffure%20%26%20Esth%C3%A9tique!5e0!3m2!1sfr!2sfr!4v1719748722758!5m2!1sfr!2sfr" 
                        width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </article>
                    <article className="contact">
                        <form onSubmit={handleSubmit}>
                            <h2 >Formulaire de contact</h2>
                            <label For="name"></label>
                            <input 
                                className="coordonne"
                                type="text" 
                                name="name" 
                                placeholder="Nom et Prénom" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                required />
                            <label For="mail"></label>
                            <input 
                                className="coordonne"
                                type="text" 
                                name="email" 
                                placeholder="Courriel" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                            <label For="phone"></label>
                            <input  
                                className="coordonne"
                                type="number" 
                                name="phone" 
                                placeholder="Numéro de téléphone" 
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required />
                            <fieldset>
                                <legend className="text-center">Votre demande concerne: </legend>
                                <div className="checkInput">
                                    <input 
                                        type="checkbox" 
                                        name="prestation_coiffure" 
                                        checked={prestaCoiffure}
                                        onChange={(e) => setPrestaCoiffure(e.target.checked)}/>
                                    <label For="prestation_coiffure">Coiffure</label>
                                </div>
                                <div className="checkInput">
                                    <input 
                                        type="checkbox" 
                                        name="prestation_ongle" 
                                        checked={prestaOngle}
                                        onChange={(e) => setPrestaOngle(e.target.checked)}/>
                                    <label For="prestation_ongle">Bien-être</label>
                                </div>
                                <div className="mb-4 checkInput">
                                    <input 
                                        type="checkbox" 
                                        name="prestation_skin" 
                                        checked={prestaSkin}
                                        onChange={(e) => setPrestaSkin(e.target.checked)}/>
                                    <label For="prestation_skin">Esthétique</label>
                                </div>
                            </fieldset>
                            <label For="message"></label>
                            <textarea 
                                name="message" 
                                placeholder="Votre message" 
                                rows='4'
                                cols="50"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}>
                            </textarea>
                            <div className="formBtn text-center p-4">
                                <button type="submit" className="btn" disabled={loading}>Envoyer</button>
                            </div>
                        </form>
                    </article>
                </div>
                    <article className="qrcode">
                        <h2>Flashez moi !</h2>
                        <img src="../img/qrcode.jpg" alt="QRCode" srcset="" />
                    </article>
            </div>
            {loading && <LoadingModal/>}
            <SendModal
                show = {showSend}
                message="Email envoyé avec succès, une réponse vous sera apportée dans les plus brefs délais"
                handleClose={handleCloseMessageModal}
            />
        </section>
    )
}

export default Contact;