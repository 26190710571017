import React from "react";
import "../assets/style/coiffure.css";
import prestations from "../datas.json";

const Esthetique = () => {
  // Filtrer les prestations par catégorie Esthetique
  const dataEsth = prestations.filter(prestation => prestation.category === "Esthetique");

  // Regrouper les prestations par sscategory et typeacte
  const groupedBySubcategory = dataEsth.reduce((acc, prestation) => {
    const key = `${prestation.sscategory}-${prestation.typeacte || ''}`;
    if (!acc[key]) {
      acc[key] = {
        sscategory: prestation.sscategory,
        typeacte: prestation.typeacte || '',
        prestations: []
      };
    }
    acc[key].prestations.push(prestation);
    return acc;
  }, {});

  return (
    <main className="home">
      <h1 className="hello">Esthétique</h1>
      <article className="accordion" id="accordionExample">
        {Object.keys(groupedBySubcategory).map((key, index) => {
          const group = groupedBySubcategory[key];
          return (
            <div key={index}>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    style={{backgroundColor:'#D93654', color:'#fff', borderColor:'#fff'}}
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                  >
                    {group.sscategory} {group.typeacte && `- ${group.typeacte}`}
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <header className="prestation-header">
                        <h3 className="prestation-acte-titre">Prestations</h3>
                        <h3 className="prestation-tarif-titre">Tarifs (€)</h3>
                    </header>
                    <ul>
                      {group.prestations.map(prestation => (
                        <li key={prestation.id} className="prestation-item">
                          <span className="prestation-acte">{prestation.acte}</span>  
                          <strong className="prestation-tarif">{prestation.tarif} €</strong>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </article>
    </main>
  );
};

export default Esthetique;
