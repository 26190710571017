import React from "react";
import "../assets/style/coiffure.css";
import prestations from "../datas.json";

const Coiffure = () => {
  // Filtrer les prestations par catégorie Coiffure
  let dataCoif = prestations.filter(prestation => prestation.category === "Coiffure");

  // Regrouper les prestations par sous-catégorie
  let groupedBySubcategory = dataCoif.reduce((acc, prestation) => {
    if (!acc[prestation.sscategory]) {
      acc[prestation.sscategory] = [];
    }
    acc[prestation.sscategory].push(prestation);
    return acc;
  }, {});
  

  return (
    <main className="home">
      <h1 className="hello">Coiffure</h1>
      <article className="accordion" id="accordionExample">
        {Object.keys(groupedBySubcategory).map((subcategory, index) => (
          <div key={index}>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  style={{backgroundColor:'#D93654', color:'#fff', borderColor:'#fff'}}
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index}`}
                >
                  {subcategory}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse "
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                    <header className="prestation-header">
                        <h3 className="prestation-acte-titre">Prestations</h3>
                        <h3 className="prestation-tarif-titre">Tarifs (€)</h3>
                    </header>
                  <ul>
                    {groupedBySubcategory[subcategory].map(prestation => (
                      <li key={prestation.id} className="prestation-item">
                        <span className="prestation-acte">{prestation.acte}</span>  
                        <strong className="prestation-tarif">{prestation.tarif} €</strong>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </article>
    </main>
  );
};

export default Coiffure;