import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/style/header.css";

const Header = () => {
    const navbarRef = useRef(null);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavLinkClick = () => {
        if (!isNavCollapsed) {
            setIsNavCollapsed(true);
        }
    };

    return (
        <nav className="navbar navbar-expand-xl">
            <div className="logo text-center">
                <img src="../img/logo4.jpg" alt="VE Coiffure" id="logo" className="logoimg" />
                <h1 id="ve">VE</h1>
                <div className="slogan">
                    <h1>Coiffure</h1>
                    <h2>&</h2>
                    <h1>Esthétique</h1>
                </div>
            </div>
            <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={!isNavCollapsed ? true : false}
                aria-label="Toggle navigation"
                onClick={() => setIsNavCollapsed(!isNavCollapsed)}
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div
                className={`collapse navbar-collapse headBar ${isNavCollapsed ? 'collapse' : 'show'}`}
                id="navbarSupportedContent"
                ref={navbarRef}
            >
                <div className="navigation">
                    <Link to='/' className="nav-link" aria-current='page' onClick={handleNavLinkClick}>
                        <img src="../img/home.svg" alt="home" />Accueil
                    </Link>
                    <Link to='/coiffure' className="nav-link" aria-current='page' onClick={handleNavLinkClick}>
                        <img src="../img/hair.svg" alt="hairdress" />Coiffure
                    </Link>
                    <Link to='/wellness' className="nav-link" aria-current='page' onClick={handleNavLinkClick}>
                        <img src="../img/zen.svg" alt="wellness" />Bien-être
                    </Link>
                    <Link to='/esthetique' className="nav-link" aria-current='page' onClick={handleNavLinkClick}>
                        <div className="column">
                            <img src="../img/manucure.svg" alt="manicure" />
                            <img src="../img/eye.svg" alt="beauty" />
                        </div>
                        Esthétique
                    </Link>
                    <Link to='/contact' className="nav-link" aria-current='page' onClick={handleNavLinkClick}>
                        <img src="../img/contact.svg" alt="contact" />Contact
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Header;
